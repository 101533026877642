.btn {
    border-radius: 10px;
    font-size: 15px;
    padding: 0 21px;
    height: 46px;
    line-height: 45px;    
    font-weight: 600;
    border: none;
    text-decoration: none;
    transition: opacity .15s ease;
    opacity: 1;

    &.btn-sm {
        font-size: 13px;
        border-radius: 7px;
        padding: 0 16px;
        line-height: 35px;
        font-weight: 500;
        height: 36px;
    }

    &:hover {
        opacity: 0.9;
    }
}

.btn-list {
    .btn {
        margin-bottom: 5px;
    }
}