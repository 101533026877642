.navbar-main {
    box-shadow: rgb(0 0 0 / 11%) 0 1px;

    .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 102, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
    }

    a {
        svg {
            path {
                fill: $primary;
            }
        }

        &:hover {
            svg {
                path {
                    fill: darken($primary, 20%);
                }
            }
        }
    }
}