// Animations
$enable-transitions: false;

// Body
$body-bg: #ffffff;
$body-color: #3e3e3e;

// Typography
//$font-family-sans-serif: 'Inter', sans-serif;
$font-family-sans-serif: Arial, Helvetica, sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

$border-radius: 15px;
$grid-gutter-width: 20px;

// Colors
$blue: #06f;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;
