main {
    padding: 50px 0;

    @include media-breakpoint-up(md) {
        padding: 100px 0;
    }

    .page-title {
        margin-bottom: 40px;
        text-align: center;
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;

        @include media-breakpoint-up(md) {
            margin-bottom: 100px;
        }

        .sub {
            line-height: 1.4em;
            font-size: 18px;
            display: inline-block;
        }
    }
}

.logo-img {
    img {
        max-width: 100%;
        max-height: 100%;
    }

    figcaption {
        margin-top: 10px;
    }
}

.component {
    border: 1px solid rgb(0 0 0 / 11%);
    border-radius: $border-radius;
    padding: 20px;
    margin-bottom: 20px;

    @include media-breakpoint-up(md) {
        padding: 50px;
        margin-bottom: 50px;
    }

    &.no-border {
        padding: 0;
        border: none;
    }

    h2 {
        margin-bottom: 20px;
    }

    &.color-component {
        padding-bottom: 20px;

        .color {
            width: 100%;
            padding-top: 100%;
            position: relative;
            border-radius: 10px;
            border: 1px solid rgb(0 0 0 / 11%);
            margin-bottom: $grid-gutter-width;

            span {
                position: absolute;
                bottom: 10px;
                right: 10px;
                display: inline-block;
                color: white;
                text-shadow: 1px 1px 2px #000, 1px 1px 1px #000;
                text-align: right;
            }
        }
    }
}

footer.site-footer {
    padding: 60px 0;
    box-shadow: rgb(0 0 0 / 11%) 0 -1px;
    text-align: center;

    @include media-breakpoint-up(md) {
        text-align: left;
    }

    h3 {
        margin-bottom: 20px;
        margin-top: 30px;

        @include media-breakpoint-up(md) {
            margin-top: 0;
        }
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            margin: 0;
            padding: 0;

            a {
                color: $body-color;
                padding: 10px 0;
                display: block;
                font-size: 16px;
            }
        }
    }

    .copyright {
        color: lighten($body-color, 20%);
        margin-top: 20px;
    }
}

#cookiePopup {
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: none;

    &.show {
        display: block;
    }

    .cookie-content {
        max-width: 512px !important;
        width: 90% !important;
        background: white;
        border-radius: 8px;
        box-shadow: rgb(0 0 0 / 16%) 0px 4px 8px;
        padding: 15px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 100px;

        p.big {
            font-size: 18px;
            color: black;
        }
        p {
            color: #666;
            font-size: 14px;
        }

        .btn-primary {
            width: 100%;
        }

        .btn-secondary {
            margin-right: 10px;
        }
    }
}

.ad-footer {
    width: 100%;
    height: 100px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;

    @media(min-width: 500px) {
        height: 60px;
    }
    @media(min-width: 800px) {
        height: 90px;
    }

    .adsbygoogle {
        width: 320px;
        height: 100px;
        margin: 0 auto;

        @media(min-width: 500px) {
            width: 468px;
            height: 60px;
        }
        @media(min-width: 800px) {
            width: 728px;
            height: 90px;
        }
    }
}
