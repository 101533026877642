nav {
    width: 100%;
    
    .pagination {
        justify-content: center;
    }

    li.page-item {
        display: inline-block;
    }
}