h1, h2, h3, h4 {
    color: #000;
    line-height: 1.1em;
    letter-spacing: -.035em;
}

h1 {
    font-size: 50px;
    margin-bottom: 30px;
    text-align: center;
    font-weight: 800;
}

h2 {
    font-size: 35px;
    font-weight: 800;
}

h3 {
    font-size: 21px;
    font-weight: 800;
}

h4 {
    font-size: 16px;
    font-weight: 800;
}