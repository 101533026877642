.card.logo {
    border: 1px solid rgb(0 0 0 / 11%);
    border-radius: $border-radius;
    padding: 20px;
    margin-bottom: $grid-gutter-width;
    transition: box-shadow .15s ease;

    &:hover {
        box-shadow: inset #d8d8da 0 0 0 1px,inset #fff 0 0 0 100px!important;
    }

    .card-img-top {
        margin-bottom: 5px;
        height: 148px;
        display: flex;
        text-align: center;
        
        @include media-breakpoint-up(md) {
            margin-bottom: 20px;
            height: 210px;
        }

        img {            
            max-width: 100%;
            max-height: 100%;
            display: flex;
            align-self: center;
            margin: 0 auto;
        }
    }

    a:hover {
        text-decoration: none;
    }

    ul.colors {
        margin: 0 0 5px 0;
        padding: 0;
        list-style: 0;

        li {
            margin: 0;
            padding: 0;
            display: inline-block;
            width: 20px;
            height: 20px;
            border-radius: 3px;
            border: 1px solid rgb(0 0 0 / 11%);
            text-indent: -9999px;
        }
    }

    .card-text {
        display: none;

        @include media-breakpoint-up(md) {
            display: block;
        }
    }
}