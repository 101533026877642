input[type=text],
input[type=password],
input[type=search] {
    border-radius: 14px;
    height: 58px;
    padding: 0 21px;
    font-size: 16px;
    transition: box-shadow .15s ease;
    box-shadow: inset #d8d8da 0 0 0 1px,inset #fff 0 0 0 100px!important;
    border: none;
    color: #000!important;

    &:hover:not(:disabled) {
        box-shadow: inset #b1b0b5 0 0 0 1px,inset #fff 0 0 0 100px!important;
    }

    &:focus:not(:disabled) {
        box-shadow: inset #06f 0 0 0 1px,inset #fff 0 0 0 100px!important;
    }
}